<template>
  <el-drawer
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    size="400px"
  >
    <div style="padding: 0 10px; height: calc(50vh); overflow-y: auto">
      <StudentCourseLogList :searchForm="searchForm" />
    </div>
  </el-drawer>
</template>
<script>
import StudentCourseLogList from "./StudentCourseLogList";
export default {
  components: { StudentCourseLogList },
  props: {
    searchForm: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: () => "详情",
    },
  },
  data() {
    return {
      drawer: false,
      direction: "btt",
      size: "small",
    };
  },
  methods: {
    handleClose() {
      this.drawer = false;
      this.$parent.searchForms = {};
    },
  },
};
</script>
<style lang="scss" scoped>
.item-title {
  height: 50px;
}
.item-text1 {
  font-weight: bold;
}
</style>