var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        visible: _vm.drawer,
        direction: _vm.direction,
        "before-close": _vm.handleClose,
        size: "400px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.drawer = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            padding: "0 10px",
            height: "calc(50vh)",
            "overflow-y": "auto",
          },
        },
        [_c("StudentCourseLogList", { attrs: { searchForm: _vm.searchForm } })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }