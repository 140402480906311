var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.dialogVisible, width: "500px" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-form-inline",
          attrs: {
            model: _vm.formInfo,
            rules: _vm.rules,
            inline: false,
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "学员", prop: "userId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    loading: false,
                    placeholder: "请选择",
                  },
                  on: { focus: (e) => _vm.getStudentList(e.target.value) },
                  nativeOn: {
                    input: function ($event) {
                      return ((e) => _vm.getStudentList(e.target.value)).apply(
                        null,
                        arguments
                      )
                    },
                  },
                  model: {
                    value: _vm.formInfo.userId,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInfo, "userId", $$v)
                    },
                    expression: "formInfo.userId",
                  },
                },
                _vm._l(_vm.userOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("openAddModal")
                    },
                  },
                },
                [_vm._v("创建新学员")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "课程", prop: "courseId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    loading: false,
                    placeholder: "请选择",
                  },
                  on: { focus: (e) => _vm.getCourseList(e.target.value) },
                  nativeOn: {
                    input: function ($event) {
                      return ((e) => _vm.getCourseList(e.target.value)).apply(
                        null,
                        arguments
                      )
                    },
                  },
                  model: {
                    value: _vm.formInfo.courseId,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInfo, "courseId", $$v)
                    },
                    expression: "formInfo.courseId",
                  },
                },
                _vm._l(_vm.courseOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }