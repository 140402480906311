<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" width="500px">
    <el-form
      :model="formInfo"
      :rules="rules"
      :inline="false"
      ref="ruleForm"
      label-width="80px"
      class="demo-form-inline"
    >
      <el-form-item label="学员" prop="userId">
        <el-select
          @focus="(e)=>getStudentList(e.target.value)"
          @input.native="(e)=>getStudentList(e.target.value)"
          filterable
          clearable
          :loading="false"
          v-model="formInfo.userId"
          placeholder="请选择"
        >
          <el-option
            v-for="item in userOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button
          style="margin-left: 10px"
          type="primary"
          @click="$emit('openAddModal')"
          >创建新学员</el-button
        >
      </el-form-item>
      <el-form-item label="课程" prop="courseId">
        <el-select
          @focus="(e)=>getCourseList(e.target.value)"
          @input.native="(e)=>getCourseList(e.target.value)"
          filterable
          clearable
          :loading="false"
          v-model="formInfo.courseId"
          placeholder="请选择"
        >
          <el-option
            v-for="item in courseOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <div style="text-align: right">
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确定</el-button
        >
        <el-button @click="dialogVisible = false">取消</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>
<script>
import { getStudentList } from "@/api/member/student";
import { getCourseList, setCourseMember } from "@/api/businesscourse/course";
export default {
  props: {
    title: { type: String, default: "" },
    submit: {
      type: Function,
    },
  },
  data() {
    return {
      dialogVisible: false,
      userOptions: [],
      courseOptions: [],
      formInfo: {
        userId: "",
        courseId: "",
      },
      rules: {
        userId: [{ required: true, message: "请选择学员", trigger: "blur" }],
        courseId: [{ required: true, message: "请选择课程", trigger: "blur" }],
      },
    }
  },
  methods: {
    async submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          setCourseMember(this.formInfo).then((res) => {
            if (res.state == "success") {
              this.$message.success("添加成功");
              this.$parent.getListHandler();
              this.dialogVisible = false;
            }
          });
        }
      });
    },
    getStudentList(val) {
      getStudentList({name:val}).then((res) => {
        this.userOptions = res.body.list.map(i=>({
          value:i.userId,
          label:i.name
        }));
      });
    },
    getCourseList(val) {
      getCourseList({title:val}).then((res) => {
        this.courseOptions = res.body.map(i=>({
          value:i.id,
          label:i.title
        }));
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    initData() {
      this.resetForm("ruleForm");
      this.formInfo = {
        userId: "",
        courseId: "",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form-item .el-form-item {
  margin-bottom: 22px;
}
</style>